<template>
	<div class="mission row justify-content-center text-secondary pt-3 pt-lg-4" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
		<div class="col-12 col-xl-10">			
			<div class="mission__main px-lg-3 pt-2 pt-md-0">
				<img v-if="deviceMode != 'mobile'" class="mission__title d-block mx-auto mb-4" src="@/assets/images/mission/img_m_title.png" alt="淡水八景從哪來">
				<img v-else class="mission__title d-block mx-auto mb-4 mb-md-0" src="@/assets/images/mission/img_m_title_s.png" alt="淡水八景從哪來">
				
				<div class="mission__contain row justify-content-center">
					<div class="col-xl-9">
						<div class="row mb-4">
							<div class="col-md-6 d-flex align-items-center">
								<p class="mb-md-0"><span class="font-weight-bold">「淡水八景」</span>的前身為「淡北八景」，出自於《淡水廳志》中，日治時期淡水河流域一帶的景致。臺灣第一本介紹淡水的地理、歷史、鄉土考察、導覽、淡水之美的手冊，則是臺灣鄉土史學家 —柯設偕老師的編寫完成的《詩美之鄉─淡水》一書，描述著淡水豐富的色彩。</p>
							</div>
							<div class="col-md-6 d-flex align-items-center">
								<img class="w-100" src="@/assets/images/mission/img_m_1.png" alt="">								
							</div>
						</div>
						<div class="row mb-4">
							<div class="col-md-6 order-md-12 d-flex flex-column justify-content-center justify-content-xl-between">
								<p class="mt-lg-3">在1927年，淡水即已入選為台灣八景之一，在柯設偕老師『淡水的色彩』一文中讚嘆：<span class="font-weight-bold">沒有見過「淡水」，不算到過台灣！</span>由此可見淡水的魅力所在。</p>
								<span v-if="deviceMode != 'mobile'" class="figure-caption mb-3">◀『柯設偕 校長』在1930年為紀念「紅毛城三百年歷史」所編寫的《詩美之鄉-淡水》 </span>
							</div>
							<div class="col-md-6 order-md-1">
								<img class="w-100 mb-2 mb-md-0" src="@/assets/images/mission/img_m_2.png" alt="">
								<span v-if="deviceMode == 'mobile'" class="figure-caption d-inline-block">▲『柯設偕 校長』在1930年為紀念「紅毛城三百年歷史」所編寫的《詩美之鄉-淡水》 </span>							
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-md-6 d-flex align-items-center">
								<p class="mb-0">淡水鎮於1966年重新規劃並推動當地觀光，「淡水八景」再次躍上舞台。從烈祠松濤、大屯春色、鷺洲泛月、漁港堤影、海口嚥日、煙雨孤帆、觀音吐霧、黌岡遠眺等迷人景致。</p>
							</div>
							<div class="col-md-6 d-flex align-items-center">
								<img v-if="deviceMode != 'mobile'" class="pl-3 pr-4 pl-lg-4 pr-lg-5 w-100" src="@/assets/images/mission/img_m_3.png" alt="">
								<img v-else class="my-4 w-100" src="@/assets/images/mission/img_m_3_s.png" alt="">
							</div>
							<div class="col-12 mt-md-4">
								<p>時至2011年新北升格為直轄市，淡水區再談「新淡水八景」，以全民大公投的方式讓全民投入八景的投票，選出最符合民眾的「八景」，其中包含：<span v-if="deviceMode == 'mobile'">埔頂攬勝、大屯飛翠、沙崙看海、水岸畫影、紅樹傍橋、河口霞天、觀音水月、滬街訪古。</span></p>
								<img v-if="deviceMode != 'mobile'" class="w-100" src="@/assets/images/mission/img_m_4.png" alt="">
							</div>
							<img v-if="deviceMode == 'mobile'" class="tamsui2011" src="@/assets/images/mission/img_m_4_s.png" alt="">
							<div class="col-12">
								<p class="mt-4 mt-md-5">隨著新北市政府對淡水區投入更多的建設與發展，2021年的淡水成為休閒、觀光、旅遊、購物的多元生活新型態區。在完整的交通網路升級、串接的條件下，絕美的景色、感動的故事、有趣的事件、創意的美食等信手捻來，都有意想不到的驚奇。因此<span class="font-weight-bold">淡水區公所</span>與<span class="font-weight-bold">慈暉文教基金會</span>聯合舉辦<span class="font-weight-bold">『淡水八景4.0』—秒懂淡水就該這麼玩</span>活動，就是要帶你體驗全新淡水生活型態、台灣生活新模式！</p>
							</div>
						</div>						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
